import React, { useState, useEffect, useRef, useMemo } from 'react';
import './index.scss'
import { Modal, Tooltip } from 'antd';
import {HexString} from 'aptos'
import classnames from 'classnames'
import { CloseOutlined } from '@ant-design/icons';
import ethereum from '../../assets/images/ConnectWallet/ethereum.png'
import binance from '../../assets/images/ConnectWallet/binance.png'
import solana from '../../assets/images/ConnectWallet/solana.png'
import aptos from '../../assets/images/ConnectWallet/Aptos.png'
import avalanche from '../../assets/images/ConnectWallet/avalanche.png'
import polygon from '../../assets/images/ConnectWallet/polygon.png'
import metamask from '../../assets/images/wallets/metamask.png'
import walletconnect from '../../assets/images/wallets/walletconnect.png'
import binanceChain from '../../assets/images/wallets/binance-chain.png'
import math from '../../assets/images/wallets/math.png'
import onto from '../../assets/images/wallets/onto.png'
import coin98 from '../../assets/images/wallets/coin98.png'
import ledger from '../../assets/images/wallets/ledger.png'
import solflare from '../../assets/images/wallets/solflare.png'
import sollet from '../../assets/images/wallets/sollet.png'
import {createProviderController} from '../../wallet/web3/createProviderController'
import connectWallet from '../../wallet/web3/connectWallet'
import checkboxUncheck from '../../assets/images/ConnectWallet/checkbox-uncheck.svg'
import checkboxChecked from '../../assets/images/ConnectWallet/checkbox-checked.svg'
import {connect as reducxConnect} from 'react-redux'
import {setChain,setconnect, connect, disconnect} from '../../store'
import { useWallet} from '@manahippo/aptos-wallet-adapter';
function ConnectWalletModal(props) {
  const [selectChain, setSelectChain] = useState('Aptos')
  const [isCheck, setIsCheck] = useState(true)
  const { wallets:aptosWallets, select: aptosSelect, disconnect:aptosDisconnect, connect:aptosConnect,  wallet: aptosWallet, account} = useWallet();
  console.log(useWallet)
  const [aptosConnecting, setAptosConnecting] = useState(false)



  const handleAptosConnect = async (walletName) => {
    await aptosDisconnect()
    console.log(aptosConnect)
    aptosSelect(walletName);
    setAptosConnecting(true)
  }
  const hexStringV0ToV1 = (v0) => {
    if (typeof v0 === 'string') {
      return new HexString(v0);
    } else if (v0.hexString) {
      return new HexString(v0.toString());
    } else {
      throw new Error(`Invalid hex string object: ${v0}`);
    }
  };

  useEffect(async () => {
    if (selectChain === 'Aptos' && aptosWallet && aptosConnecting) {
      props.dispatch(setconnect('1'))
      props.handleOk()
      try {
        await aptosConnect()
        props.dispatch(setChain(selectChain))
        localStorage.setItem('kepler_chain', selectChain)
      } catch(e) {
        console.log(e)
      } finally {
        setAptosConnecting(false)
      }
    }
  }, [selectChain, aptosWallet, aptosConnecting])

  useEffect(() => {
    console.log(account)
    if (props.chain === 'Aptos' && account?.address) {
      const address = hexStringV0ToV1(account?.address)
      console.log(address.toString())
      props.dispatch(connect(address?.toString()))
      props.dispatch(setconnect(''))
    }
  }, [props.chain, account])

  // useEffect(() => {
  //   setSelectChain(props.connectWalletChain)
  // }, [props.connectWalletChain])

  return (
    <Modal visible={props.isVisible} onOk={props.handleOk} onCancel={props.handleCancel} closable={false} footer={false} className="connect-wallet-modal">
      <div className="connect-wallet-modal-header">
        <div className="modal-title">
          <h2 className="fw500">Connect to a wallet</h2>
          <div className="modal-close" onClick={props.handleCancel}><CloseOutlined /></div>
        </div>
        {/* <div className="fw500 thermofservice-checkbox"><img className="checkbox" src={isCheck ? checkboxChecked : checkboxUncheck} alt=""  onClick={_ => setIsCheck(!isCheck)} /><div>I have read, understand, and agree to the <a className="highlight">Terms of Service</a>.</div></div> */}
      </div>
      <div className={classnames(["wallet-choose", {"disabled": !isCheck}])}>
        {/* <h3 className="fw500 wallet-choose-title">Step1: Choose Network</h3> */}
        {/* <div className="chain-list">
         
        <div className={classnames(["chain-item", {"active": selectChain === 'Aptos'}])} 
          onClick={_ => {
            setSelectChain('Aptos')
          }}
          > 
            <>
            <div className="chain-image">
                <img src={aptos} alt="ethereum" />
              </div>
              <div className="chain-name">Aptos</div>
            </>
            
          </div>
        </div> */}
        {/* <h3 className="fw500 wallet-choose-title">Step2: Choose Wallet</h3> */}
        <div className="wallet-list">
          
        <>
        {
          console.log(aptosWallets)
        }
              {aptosWallets.map(wallet => {
                return (
                  <div className="wallet-item sol-wallet-item" key={wallet.adapter.name} onClick={async _ => {
                    handleAptosConnect(wallet.adapter.name)
                  }}>
                    <div className="wallet-image-wrapper">
                      <img src={wallet.adapter.icon
                      } alt={`${wallet.adapter.name} icon`} className="wallet-image" />
                    </div>
                    <div className="wallet-name">{wallet.adapter.name}</div>
                  </div>
                )
              })}
            </>
          
        </div>
      </div>
    </Modal>
  )
}

export default reducxConnect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  ConnectWalletModal
);