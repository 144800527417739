export default [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "nft",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "nftCount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "referrer",
        "type": "address"
      }
    ],
    "name": "buy",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "queryGrade",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "minReferalAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "maxReferalAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "commissionRate",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "discountRate",
            "type": "uint256"
          }
        ],
        "internalType": "struct IPassportMarket.Grade",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "queryGrades",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "minReferalAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "maxReferalAmount",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "commissionRate",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "discountRate",
            "type": "uint256"
          }
        ],
        "internalType": "struct IPassportMarket.Grade[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "referrer",
        "type": "address"
      }
    ],
    "name": "queryReferrals",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "nft",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "nftCount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "user",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "fee",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "discountedFee",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "reward",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "time",
            "type": "uint256"
          }
        ],
        "internalType": "struct IPassportMarket.Referral[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "querySupportedNfts",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "nft",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "price",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "maxSupply",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "sales",
            "type": "uint256"
          }
        ],
        "internalType": "struct IPassportMarket.NftInfoView[]",
        "name": "views",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
]