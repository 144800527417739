export const Grades = [
    {
        id: 0,
        name: ''
    },
    {
        id: 1,
        name: 'Sliver'
    },
    {
        id: 2,
        name: 'Gold'
    },
    {
        id: 3,
        name: 'Platinum'
    },
    {
        id: 4,
        name: 'Diamonds'
    }
]

