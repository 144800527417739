




import React, { useState, useEffect, useRef, useCallback} from 'react';
import {Button, Tooltip} from 'antd'
import iconMenu from '../../assets/images/home/icon-menu.svg'
import { Menu, Dropdown } from 'antd';
// import iconArrow from '../../assets/images/home/icon-arrow.svg'
// import logo from '../../assets/images/home/logo.svg'
// import iconLanguage from '../../assets/images/home/icon-language.svg'
// import iconDownload from '../../assets/images/home/icon-download.svg'
import logo from '../../assets/images/nav/logo.svg'
import menu from '../../assets/images/nav/menu.svg'
import classnames from 'classnames'
import './Airnav.scss'
import { useTranslation} from 'react-i18next'
import ConnectWallet from '../ConnectWallet';
import Login from '../Login';
import Menumint from './Menumint'
import {connect, useSelector} from 'react-redux'
import notification from '../notification'
import Share from '../Passport/Modal'

import Bus from '../../lib/eventBus'
import { NavLink, useLocation } from 'react-router-dom';
import { get } from '../../http';
import { ZERO_ADDRESS } from '../../lib/util';
import { InviteCondition } from '../../global';
import store from '../../store';

let communityList = [
  {
    name: 'twitter',
    icon: require('../../assets/images/airdrop/twitter.svg').default,
    activeIcon: require('../../assets/images/airdrop/twitter_active.svg').default,
    link: 'https://twitter.com/KeplerHomes'
  },
  {
    name: 'telegram',
    icon: require('../../assets/images/airdrop/telegram.svg').default,
    activeIcon: require('../../assets/images/airdrop/telegram_active.svg').default,
    link: 'https://twitter.com/KeplerHomes'
  },
  {
    name: 'discord',
    icon: require('../../assets/images/airdrop/discord.svg').default,
    activeIcon: require('../../assets/images/airdrop/discord_active.svg').default,
    link: 'https://discord.gg/keplerhomes'
  },
  {
    name: 'medium',
    icon: require('../../assets/images/airdrop/medium.svg').default,
    activeIcon: require('../../assets/images/airdrop/medium_active.svg').default,
    link: 'https://medium.com/@KeplerHomes'
  }

]

function Header(props) {

  let location = useLocation()
  let { t ,i18n} = useTranslation()
  let language = i18n.language.split('-')[0]
  let [hasBg, setBg] = useState(false)
  let [isWhite, setIsWhite] = useState(false)
  let [showShare, setShowShare] = useState(false)
  const [isConnectWalletVisible, setIsConnectWalletVisible] = useState(false)
  let [referAddress, setAddress] = useState(location.search ? location.search.replace('?','').split('=')[1]?.toLowerCase():'')
  let [inviter, setInviter] = useState('')
  let [isFold, setFold] = useState(false)

  const handleConnectWalletOk = () => {
    setIsConnectWalletVisible(false)
  }

  const handleConnectCancel = () => {
    setIsConnectWalletVisible(false)
  }

  const handleConnectWallet = () => {
    setIsConnectWalletVisible(true)
  }
  
  const copyAddress = useCallback(async () => {
    if(!props.account) {
      notification.error({
          message: t('Please connect your wallet first')
        });
        return
     }
    await navigator.clipboard.writeText('https://'+window.location.host+'/?address='+props.account);
    notification.success({
      message: t('Invitation link has been generated and copied, you can paste and send it to your friends now!'),
    });
  }, [props.account]);
  useEffect(()=> {
    window.addEventListener('scroll', function() {
      if(document.documentElement.scrollTop > 0) {
        setBg(true)
      } else {
        setBg(false)
      }
    })
  }, [])

  
  let foldfn = () => {
    Bus.emit('foldChange', !isFold);
}
useEffect(()=> {
  referAddress && setInviter(referAddress)
}, [referAddress])
useEffect(() => {
    Bus.addListener('foldChange', (isfold) => {
        setFold(isfold)
    });
}, [])

useEffect(async()=>{
  console.log(props)
  if(props.account) {
    get('/api/account/white/passport/aptos', {
       address: props.account,
       referrer: ZERO_ADDRESS,
       amount: 1
    }).then(res => {
      setIsWhite(res.data.status > 1 ? true:false)
    })
  } else {

  }
}, [props.account])

  

  return (
    <div>
    <div className={classnames(["airdrop-header mint-header flex flex-between", {"has-bg": hasBg}])}>
     <div className="logo-wrap flex flex-center flex-middle flex-between logo-wrap-fold" >
         {/* <a href="/" className='logo'> */}
           <img src={logo} alt="" className='logo'/>
              <a className="nav-item cf  fz-16 m-l-40 islink" target="_blank" href="https://kepler.homes">
                    Home
              </a>
              <NavLink className="nav-item cf  fz-16 m-l-40 islink" to='/'>
                    Presale
              </NavLink>
              {/* <a className="nav-item cf  fz-16 m-l-40 islink" href="https://presale-bsc.kepler.homes" target="_blank">
                    <img src={require('../../assets/images/ConnectWallet/binance.png')} alt="" style={{width: 20}} className="m-r-5"/>
                    BSC Presale
              </a>
              <a className="nav-item cf  fz-16 m-l-40 islink" href="https://presale-arbitrum.kepler.homes" target="_blank">
                    <img src={require('../../assets/images/ConnectWallet/arbitrum.jpg')} alt="" style={{width: 20}} className="m-r-5"/>
                    Arbitrum Presale
              </a> */}

            <div className="mobile-menu" onClick={foldfn}>
                <img src={iconMenu} alt="" />
            </div>

      </div>
      <div className="header-right">
        {/* <Button className='invite bgold' onClick={()=>setShowShare(true)}>Invite friends</Button> */}
        <span className='flex flex-center'>
          <Button className='invite m-r-10' onClick={()=>copyAddress()}>Invite friends</Button>
          <Tooltip title={`Holders whose KEPL asset is over ${store.getState().referMin} APT could get a referral bonus by inviting others.`}>
                  <span>
                  <img className='m-l-3' src={require('../../assets/images/passport/question.svg').default} alt="" />
                  </span>
          </Tooltip>
        </span>
        <ConnectWallet hideChain={['Avalanche','ETH', 'Polygon', 'Solana']} isVisible={isConnectWalletVisible} handleOk={handleConnectWalletOk} handleCancel={handleConnectCancel} />

      </div>
    </div>
      <div className="show-m">
        <Menumint></Menumint>
      </div>

      <Share isVisible={showShare} onClose={()=>setShowShare(false)} account={props.account}/>
    </div>
  )
}

export default connect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  Header
);

