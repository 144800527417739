import Airnav from "../components/layouts/Airnav"
import Mintfooter from "../components/layouts/Mintfooter"
import './Passport.scss'
import { Button, Carousel} from "antd"
import { Link } from "react-router-dom"
import { tokenList } from "../lib/airdrop"
import {queryGrades, queryGradeByOwner} from '../contract/methods/passport'
import { useCallback, useEffect, useState} from "react"
import { addPoint, findNameByAddress, fromUnit, numFormat, toUnit, toWei } from "../lib/util"
import { toFixed } from "accounting"
import { Tabs } from 'antd';
import Passport from "../components/Passport"
import {connect} from 'react-redux'
import notification from "../components/notification"
import { Grades } from "../lib/passport"

const { TabPane } = Tabs;





export default connect(
  (state, props) => {
    return {...state, ...props}
  }
)( (props)=> {
    let [activeKey, setActive] = useState('1')
    let [myGrade, setMyGrade] = useState('')
    useEffect(async()=> {
      if(!props.account) {
        return
      }
      let grades = await queryGradeByOwner(props.account)
      setMyGrade(Grades[grades.id])
    }, [props.account])
    const copyAddress = useCallback(async () => {
      if(!props.account) {
        notification.error({
            message: ('Please connect your wallet first')
          });
          return
       }
      await navigator.clipboard.writeText('https://'+window.location.host+'?referee='+props.account);
      notification.success({
        message: ('Invitation link has been generated and copied, you can paste and send it to your friends now! '),
      });
    }, [props.account]);
    return (
        <div className="passport-wrap w100 m-t-0">
            <Airnav/>
            <img src={require('../assets/images/passport/banner5.png')} alt="" />
            <div className="flex flex-middle">
              
              <div className="content-tab">
                {/* {
                  myGrade.name && (
                  <div className="agent cf">
                    <span className="fz-32 cf">Hello, </span>
                    <span className="cgold fz-32">{myGrade.name} card </span>
                    <span  className="fz-32 cf">agent</span>
                    <div className="fz-18 m-t-10">
                    click the button below to share the link with your friends, you can 
                  of the purchase amount of your friends as a reward.
                    </div>
                  <div className="invite bgold m-t-20 pointer" onClick={copyAddress}>Invite friends</div>
                </div>
               )
                } */}
              
                {/* <Tabs className='my-tab mint-tab' activeKey="1">
                  <TabPane key={1} tab="Guild"> */}
                  <div className="mint-tab">
                    <Passport />
                  </div>
                  {/* </TabPane>
                  <TabPane key={2} tab="Agent"></TabPane>
                </Tabs> */}
              </div>
            </div>
            <Mintfooter/>
        </div>
    )
})