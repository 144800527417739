export default 
{
  "APT": "0x1::aptos_coin::AptosCoin",
  "KEPL": "0xc643abcec4cedeba16538a59ec5a99700d32bc7da6e9e407b38d5774ee2e9125::KEPL::T",
  "KEME": "0xc643abcec4cedeba16538a59ec5a99700d32bc7da6e9e407b38d5774ee2e9125::KEME::T",
  "VeKEPL": "0xc643abcec4cedeba16538a59ec5a99700d32bc7da6e9e407b38d5774ee2e9125::VeKEPL::T",
  "PreKEPL": "0x9a0941b276f496cfa374e433c56e646182fb667578a38feafebfe497ddbbb8e1::PerKEPL::T",
  "Bridge": "0x9812effadb5afaef205f5c624bd2a45c0788d613c9a94bb960a6573e5144ad78::coin_bridge",
  "Farm": "0x9a0941b276f496cfa374e433c56e646182fb667578a38feafebfe497ddbbb8e1::farm_003",
  "PassportMint": "0x9a0941b276f496cfa374e433c56e646182fb667578a38feafebfe497ddbbb8e1::passport_mint_004",
  "PassportStake": "0x9a0941b276f496cfa374e433c56e646182fb667578a38feafebfe497ddbbb8e1::passport_stake_003",
  "Presale": "0x9a0941b276f496cfa374e433c56e646182fb667578a38feafebfe497ddbbb8e1::presale"
}