import { getNodeUrl} from "../global";
import { findAddressByName } from "../lib/util";

const moduleType = findAddressByName('Presale')
const collectionName = 'Kepler Passport'
export const  queryResource = async (userAddress, resourceType) => {
    let url = `${getNodeUrl()}/accounts/${userAddress}/resource/${resourceType}`;
    //console.log("queryResource", url);
    const response = await fetch(url, { method: "GET" });
    if (response.status == 404) {
        return null;
    }
    // if (response.status != 200) {
    //     assert(response.status == 200, await response.text());
    // }
    return await response.json();
}

export const  queryModules = async (moduleAddress)=> {
    let url = `${getNodeUrl()}/accounts/${moduleAddress}/modules`;
    console.log("queryModules: ", url);
    const response = await fetch(url, { method: "GET" });
    if (response.status == 404) {
        return [];
    }
    // if (response.status != 200) {
    //     assert(response.status == 200, await response.text());
    // }
    return await response.json();
}

export const  queryModuleResource = async (user, structName, moduleType2=moduleType)=> {
    let resourceType = `${moduleType2}::${structName}`;
    return await queryResource(user, resourceType);
}

export const  queryTableItem = async (tableHandler, keyType, valueType, key) => {
    let url = `${getNodeUrl()}/tables/${tableHandler}/item`;
    let data = { key_type: keyType, value_type: valueType, key };
    let headers = { "Content-Type": "application/json" };
    //console.log("queryTableItem", url, data);
    const response = await fetch(url, { method: "POST", headers, body: JSON.stringify(data) });
    if (response.status == 200) {
        return await response.json();
    } else {
        return null;
    }
}
export const  queryModuleStorage = async (user) => {
    return await queryModuleResource(user, `ModuleStorage`);
}

export const  queryResourceAccount = async (user) => {
    let storage = await queryModuleStorage(user);
    let tableHandler = storage && storage.data.resource_accounts.handle;
    let account =
        tableHandler &&
        (await queryTableItem(`${tableHandler}`, "0x1::string::String", "address", collectionName));
    console.log("resourceAccount", account);
    return account;
}

export const queryCollectionConfig = async ()=> {
    let user = findAddressByName('PassportMint').split('::')[0]
    let resourceAccount = await queryResourceAccount(user);
    return resourceAccount && (await queryModuleResource(resourceAccount, `CollectionConfig`));
}
export const  queryCollecitonData = async(handle, collection_creator) =>{
    return queryTableItem(
        handle,
        `${findAddressByName('PassportStake')}::CollectionId`,
        `${findAddressByName('PassportStake')}::CollectionData`,
        {
            creator: collection_creator,
            name: Buffer.from(collectionName, "utf-8").toString("hex"),
        }
    );
}

export const  isRegistered = async(accountAddress)=> {
    let resourceType = `0x1::coin::CoinStore<${findAddressByName('VeKEPL')}>`;
    let resource = await queryResource(accountAddress, resourceType);
    return resource && resource.type == resourceType;
}

export const getNFTList = async (user) => {
    
    let list = []
    let page = 0
    let limit = 993
    const getNFTData = async (user, page, limit) => {
        // let url = "https://wqb9q2zgw7i7-mainnet.hasura.app/v1/graphql" //mainnet
        let url = "https://knmpjhsurbz8-testnet.hasura.app/v1/graphql"; //testnet
        let postData = {
            operationName: "AccountTokensData",
            variables: {
                owner_address: `${user}`,
                limit,
                offset: limit*page,
            },
            query: 'query AccountTokensData($owner_address: String, $limit: Int, $offset: Int) {\n  current_token_ownerships(\n    where: {owner_address: {_eq: $owner_address}, amount: {_gt: "0"}}\n    limit: $limit\n    offset: $offset\n  ) {\n    token_data_id_hash\n    name\n    collection_name\n    table_type\n    property_version\n    amount\n    __typename\n  }\n}',
        };
        let headers = { "Content-Type": "application/json" };
        const response = await fetch(url, { method: "POST", headers, body: JSON.stringify(postData) });
        if (response.status == 200) {
            let json = await response.json();
            json && json.data && (list = list.concat(json.data.current_token_ownerships));
            console.log(list)
            if(json && json.data && json.data.current_token_ownerships.length >= limit) {
                page +=1
                await getNFTData(user, page, limit)
            }
        } 
    }
    await getNFTData(user, page, limit)
    console.log(list)

    return list.filter(item => item.collection_name == collectionName && item.amount > 0)
}