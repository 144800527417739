

export default [
    {
        title: "1. Any restriction for holding passports within the account?",
        content: "There is no restriction for holding passports for any single account. You can hold multiple passports of the same kinds. And the passport itself, as an NFT, is linked with the wallet."
    },
    {
        title: "2. What are the benefits for the passport:",
        content: <span>
            Time wise: <br/>
        Kepler passport comes with 1000 hours of gameplay  <br/>
        In-game benefits: players with passports have access to the daily task and token farming. One thing to note is: The NFT obtained in the game are not transferable by default, but it can be transferred with consumption of certain amount of KEPL (the governance token) <br/>
    
    Airdrop benefits: Priority during airdrop for passport holders. <br/>
    
    DID Benefits: Passport holders have a special in-game appearance to show (for example: color and title of the IDs) and passport holders will have other benefits like: discount in purchasing game items, token consumption, and limited time promotions.
        </span>
    },
    {
        title: "3. How many Passport can be Minted per address?",
        content: <span>
             You can mint up to 2 passports per address
        </span>  
    },
    {
        title: "4. The number and price of passports for this total Mint:",
        content: <span>     
            For this beta version sales, the number of passports issued is limited <br/>
            We plan to issue 5000 copies of Kepler passport, 4,000 are allocated to whitelist users, the first 1,000 for whitelist users are priced at 78 Apt, the next 3,000 are priced at 85 Apt; the remaining 1,000 copies are priced at 104 Apt for public sale.
        </span>
    },
    {
        title: "5. Is passport resale-able in the marketplace?",
        content: "Yes if the remaining hours are more than 70% of its initial hours (and listing price would be proportional to the remaining hours of corresponding passport). If not, the passport cannot be listed on the marketplace."
    },
    {
        title: "6. What if the hours are used up in the passport?",
        content: "You can buy the hours recharge card to charge the passport."
    }
]