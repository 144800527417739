import ADDRESS  from './aptosAddress'
import { getCurAddress as getCurAddressName } from '../../global'

export function getAddress () {
  return ADDRESS
}

export function getCurAddress () {
  return getCurAddressName()
}
