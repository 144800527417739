import "./Claim.scss"
import {Button, Table, Tooltip} from 'antd'
import { useState } from "react"
import { useEffect } from "react"
import { connect } from 'react-redux'
// import { queryBuyRecords } from "../../contract/methods/presale"
import { balanceOf } from "../../contract/methods"
import { addPoint, ChainIdMap, findAddressByName, formatTime, formatTimeShort, fromUnit } from "../../lib/util"
import { format } from "date-fns"
import { get } from "../../http"
import Pagination from "../Base/Pagination"
import { chainSymbolMap } from "../../wallet/helper/getNetworkData"
import store from "../../store"
import { queryModuleResource } from "../../wallet/client"
import { InviteCondition } from "../../global"
const PAYMENT= 'USDC'
export default connect(
    (state, props) => {
      return {...state, ...props}
    }
  )( (props)=> {
    let columns = [{
        title: "Address",
        key: "buyer",
        dataIndex: "buyer",
        render: (user, record) => (
          <Tooltip title={user}>{addPoint(user)}</Tooltip>
        )
    },{
        title: "Buy amount",
        key: "payment",
        dataIndex: "payment",
        render: (amount, record) => (
          <span className='flex-1'>{Number(fromUnit(amount)).toFixed(2)} {PAYMENT}</span>
        )
    },{
        title: "Rewards amount",
        key: "referrer_reward",
        dataIndex: "referrer_reward",
        render: (amount, record) => (
          <span className='flex-1'>{Number(fromUnit(amount)).toFixed(2)} {PAYMENT}</span>
        )
    },{
        title: "Transition time",
        key: "buy_time",
        dataIndex: "buy_time",
        render: (time, record) => (
          <span className='flex-1'>{formatTime(time)}</span>
        )
    },
    // {
    //     title: "Hash",
    //     key: "hash",
    //     dataIndex: "hash",
    //     render: (hash, record) => (
    //       <a className='flex' target="_blank" title={hash} href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}/tx/${hash}`}>{addPoint(hash)}
    //       <img src={require('../../assets/images/mint/share.svg').default} className="m-l-5" alt="" />
    //       </a>
    //     )
    // }
  ]
    let limit = 10
    let [loading, setLoading] = useState(false)
    let [list, setList] = useState([])
    let [page, setPage] = useState(1)
    let [total, setTotal] = useState(0)
    let getRecordData = async () => {
        setLoading(true)
        let myData = await queryModuleResource(props.account, 'UserStorage', findAddressByName('Presale'))
       setList(myData.data.reference_records)
       setLoading(false)
    }

    let pageChange = (p) => {
      setPage(p)
    }
    useEffect(async()=>{
        if(props.account) {
            getRecordData(page)
        }
        
    },[props.account, page])
    return (
        <div className="claim-wrap">
             <p className="c06 fz-14 invite-tip">Holders whose KEPL asset is over {InviteCondition}APT could get a referral bonus by inviting others.</p>
            <Table loading={loading} columns={columns} dataSource={list} pagination={false} className="my-table m-t-20" />
            {
              total > 0 && <div className="flex flex-last m-t-20">
              <Pagination currentpage={page} limit={limit} total={total} pageChange={pageChange}/>
              </div>
            }
              
            
        </div>
    )
})