export const tokenList = [
    {
        tokenName: 'AXS',
        name: 'Axie Infinity',
        type: 4,
        active: true,
        className: 'axs',
        icon: require('../assets/images/token/AXS.png')
    },
    {
        tokenName: 'SAND',
        className: 'bend',
        name: 'The Sandbox',
        className: 'sandbox',
        type: 5,
        icon: require('../assets/images/token/SAND.png')
    },
    {
        tokenName: 'WRLD',
        name: 'NFT Worlds',
        className: 'wrld',
        type: 6,
        active: true,
        icon: require('../assets/images/token/WRLD.png')
    },
    {
        tokenName: 'BEND',
        className: 'bend',
        name: 'BendDAO',
        type: 3,
        icon: require('../assets/images/token/BEND.png')
    },
    {
        tokenName: 'APE',
        name: 'ApeCoin',
        type: 1,
        active: true,
        icon: require('../assets/images/token/APE.png')
    },
    {
        tokenName: 'LOOKS',
        name: 'LOOKSRARE',
        className: 'looksrare',
        type: 2,
        icon: require('../assets/images/token/LOOKSRARE.png')
    }
]

