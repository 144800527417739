import React, { useMemo,useEffect} from "react";
import { useLocation } from 'react-router'
import { Routes, Route, Link } from "react-router-dom";
import Layout from './components/layouts/Layout'
import Passport from "./pages/Passport";
import './i18n'
// import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import Bus from './lib/eventBus'
import Presale from "./pages/Presale";

import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';

import {ConnectionProvider,
  WalletProvider,
  HippoWalletAdapter,
  AptosWalletAdapter,
  HippoExtensionWalletAdapter,
  MartianWalletAdapter,
  FewchaWalletAdapter,
  PontemWalletAdapter,
  SpikaWalletAdapter,
  RiseWalletAdapter,
  FletchWalletAdapter,
  TokenPocketWalletAdapter,
  ONTOWalletAdapter,
  BloctoWalletAdapter,
  SafePalWalletAdapter
} from '@manahippo/aptos-wallet-adapter';
function App() {
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  let {pathname} = useLocation()

  const wallets = useMemo(
    () => [
      // new HippoWalletAdapter(),
      new AptosWalletAdapter(),
      new BloctoWalletAdapter({bloctoAppId: '986fd738-ba63-482e-9267-9ac86c361cdb', network: 'mainnet'}),
      new MartianWalletAdapter(),
      new FewchaWalletAdapter(),
      // new HippoExtensionWalletAdapter(),
      new PontemWalletAdapter(),
      // new SpikaWalletAdapter(),
      // new RiseWalletAdapter(),
      // new FletchWalletAdapter(),
      // new TokenPocketWalletAdapter(),
      // new ONTOWalletAdapter(),
      // new SafePalWalletAdapter()
    ],
    []
  );
  useEffect(()=> {
    document.title = `KEPLER | ${pathname.replace('/', '')?pathname.replace('/', '').toUpperCase():'PRESALE-APTOS'}`
    if (window.innerWidth < 768) {
      Bus.emit('foldChange', false);
    }
  }, [pathname])

  return (
    // <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect onError={(error) => {
        console.log('Handle Error Message', error);
      }}>
        <div className="App">
          <Layout>
            <Routes>
              <Route path="/" element={<Presale />} />
            </Routes>
          </Layout>
        </div>
      </WalletProvider>
    // </ConnectionProvider>
  );
}

export default App;
