import Web3 from 'web3'
import {erc721ABI} from '../abi/erc721'
import {bep20ABI} from '../abi/bep20'
import nftmarket from '../testnet/Nftmarket'
import PoolFactory from '../testnet/PoolFactory'
import DepositPool from '../testnet/DepositPool'
import RewardPool from '../testnet/RewardPool'
import Bridge from '../testnet/Bridge'
import { erc20ABI } from '../abi/erc20'
import nft from '../testnet/nft'
import Airdrop from '../testnet/Airdrop'
import PassportMarket from '../testnet/PassportMarket'
import PassportStake from '../testnet/PassportStake'
import PassportMine from '../testnet/PassportMine'
import Passport from '../testnet/Passport'
import { toWei, ZERO_ADDRESS } from '../../lib/util'
import {getAddress, getCurAddress}  from '../testnet/address'
import { MaxUint256 } from '@ethersproject/constants'
import { post } from '../../http'
import notification from '../../components/notification'
import getNetworkData, { chainSymbolMap } from '../../wallet/helper/getNetworkData';
import { createProviderController } from '../../wallet/web3/createProviderController'
import store from '../../store'
import BigNumber from 'bignumber.js'
const chain = localStorage.getItem('kepler_chain') || 'Aptos'

const httpProviderURL = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
console.log(httpProviderURL)
// const provider = await createProviderController(chain).connect()
export const web3 = new Web3(new Web3.providers.HttpProvider(httpProviderURL))
// if(!web3) {
    
// }

function createWeb3(chain) {
  let httpProviderURL

  if (chain === 'ETH') {
    httpProviderURL = getNetworkData.getETHNetwork().httpProviderURL
  } else if (chain === 'BSC') {
    httpProviderURL = getNetworkData.getBSCNetwork().httpProviderURL
  } else if (chain === 'Polygon') {
    httpProviderURL = getNetworkData.getPolygonNetwork().httpProviderURL
  } else if (chain === 'Avalanche') {
    httpProviderURL = getNetworkData.getAvalancheNetwork().httpProviderURL
  }
  
  return new Web3(new Web3.providers.HttpProvider(httpProviderURL))
}

function createCurWeb3() {
  const chain = store.getState().chain
  return createWeb3(chain)
}

// / 将string转成bytes32
const stringToBytes32 = (s) => {
  let result = web3.utils.fromAscii(s);
  while (result.length < 66) {
      result = result + "0";
  }
  return result;
}


export function balanceOfBNB(user) {
  const web3 = createCurWeb3()
  return web3.eth.getBalance(user)
}
export function balanceOf (contractAddress, address) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(bep20ABI, contractAddress).methods.balanceOf(address).call()
}

export function tokensOfOwner(name, user) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(Passport, getCurAddress()[`Passport(${name})`]).methods.tokensOfOwner(user).call()
}

// passport
export function queryVariables(tokenName) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(Airdrop, getCurAddress()[`Airdrop(${tokenName})`]).methods.queryVariables().call()
}

export function queryGrades() {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(PassportMarket, getCurAddress()[`PassportMarket`]).methods.queryGrades().call()
}

export function queryGradeByOwner(owner) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(PassportMarket, getCurAddress()[`PassportMarket`]).methods.queryGrade(owner).call()
}
export function queryReferrals(owner) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(PassportMarket, getCurAddress()[`PassportMarket`]).methods.queryReferrals(owner).call()
}

export function queryGlobalView() {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(PassportMine, getCurAddress()[`PassportMine`]).methods.queryGlobalView().call()
}
export function queryReferenceRecordings(user) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(PassportMine, getCurAddress()[`PassportMine`]).methods.queryReferenceRecordings(user).call()
}


export function queryBuyAmount(user, passportaddress) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(PassportMine, getCurAddress()[`PassportMine`]).methods.queryBuyAmount(user, passportaddress).call()
}

export function queryStakeGlobalView (name) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(PassportStake, getCurAddress()[`PassportStake(${name})`]).methods.queryGlobalView().call()
}

export function queryUserView (name, user) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(PassportStake, getCurAddress()[`PassportStake(${name})`]).methods.queryUserView(user).call()
}
export function buyKeplerPassport(
  amount,
  referrer,
  isPromotional,
  signature,
  value
) {
  console.log(amount,
    referrer,
    isPromotional,
    signature,
    value)
  return new Promise(async (res, rej) => {
    try{
      const provider = await createProviderController(store.getState().chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(PassportMine, getCurAddress()[`PassportMine`]).methods.buyKeplerPassport(
        amount,
        referrer,
        isPromotional,
        signature
      )
      .send({from: address, value})
      .then((result) => {
       res(result)
       notification.success({
        message: 'Transaction Success',
        description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
      })
      })
      .catch((error) => {
        rej(error);
      });
    } catch (err) {
      rej(err);
    }
  })
}

export function buyUniversePassport(
  amount,
  referrer,
  isPromotional,
  signature,
  value
) {
  return new Promise(async (res, rej) => {
    try{
      const provider = await createProviderController(store.getState().chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(PassportMine, getCurAddress()[`PassportMine`]).methods.buyUniversePassport(
        amount,
        referrer,
        isPromotional,
        signature
      )
      .send({from: address, value})
      .then((result) => {
       res(result)
       notification.success({
        message: 'Transaction Success',
        description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
      })
      })
      .catch((error) => {
        rej(error);
      });
    } catch (err) {
      rej(err);
    }
  })
}
export function buy(
  nftname,
  count,
  referrer=ZERO_ADDRESS
) {
  return new Promise(async (res, rej) => {
    try{
      const provider = await createProviderController(store.getState().chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      console.log(getCurAddress()[`Passport(${nftname})`], count, referrer||ZERO_ADDRESS)
      new web3.eth.Contract(PassportMarket, getCurAddress()[`PassportMarket`]).methods.buy(
        getCurAddress()[`Passport(${nftname})`], count, referrer||ZERO_ADDRESS
      )
      .send({from: address})
      .then((result) => {
       res(result)
       notification.success({
        message: 'Transaction Success',
        description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
      })
      })
      .catch((error) => {
        rej(error);
      });
    } catch (err) {
      rej(err);
    }
  })
}

export function claim(name) {
  return new Promise(async (res, rej) => {
    try{
      const provider = await createProviderController(store.getState().chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(PassportStake, getCurAddress()[`PassportStake(${name})`]).methods.claim()
      .send({from: address})
      .then((result) => {
       res(result)
       notification.success({
        message: 'Transaction Success',
        description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
      })
      })
      .catch((error) => {
        rej(error);
      });
    } catch (err) {
      rej(err);
    }
  })
}
export function batchStake(name, tokenIds, lockWeeks) {
  return new Promise(async (res, rej) => {
    try{
      const provider = await createProviderController(store.getState().chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(PassportStake, getCurAddress()[`PassportStake(${name})`]).methods.batchStake(
        tokenIds, lockWeeks
      )
      .send({from: address})
      .then((result) => {
       res(result)
       notification.success({
        message: 'Transaction Success',
        description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
      })
      })
      .catch((error) => {
        rej(error);
      });
    } catch (err) {
      rej(err);
    }
  })
}

export function batchUnstake(name, tokenIds) {
  return new Promise(async (res, rej) => {
    try{
      const provider = await createProviderController(store.getState().chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(PassportStake, getCurAddress()[`PassportStake(${name})`]).methods.batchUnstake(
        tokenIds
      )
      .send({from: address})
      .then((result) => {
       res(result)
       notification.success({
        message: 'Transaction Success',
        description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
      })
      })
      .catch((error) => {
        rej(error);
      });
    } catch (err) {
      rej(err);
    }
  })
}

export function isApprovedForAll (
  address,
  operator
) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(erc721ABI, address).methods.isApprovedForAll(store.getState().account, operator).call()
}

export const setApprovalForAll = async (nftaddress, contractAddress) => {
  console.log(nftaddress, contractAddress)
  return new Promise(async (res, rej) => {
    try{
      const provider = await createProviderController(store.getState().chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(erc721ABI, nftaddress).methods.setApprovalForAll(
        contractAddress, true
      )
      .send({from: address})
      .on('transactionHash', function() {
      })
      .on('receipt', function(result){
        res()
        notification.success({
         message: 'Transaction Success',
         description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
       })
      }).on('error', function (error) {
        console.log(error)
        rej(error);
      })
      .catch((error) => {
        rej(error);
      });
    } catch (err) {
      rej(err);
    }
  })
}