

import ADDRESS_TEST  from './contract/testnet/aptosAddress'
import ADDRESS_MAIN  from './contract/mainnet/aptosAddress'
import { findAddressByName, UNIT } from './lib/util';
import { queryModuleStorage } from './wallet/client';



const NET_TYPE = 'main'
// const NET_TYPE = 'test'

const node_url_main  = 'https://fullnode.mainnet.aptoslabs.com/v1';
const node_url_test = 'https://fullnode.testnet.aptoslabs.com/v1';

export const getNodeUrl = () => {
    return NET_TYPE == 'main' ? node_url_main : node_url_test
}

export function getAddress () {
    return NET_TYPE == 'main' ? ADDRESS_MAIN:ADDRESS_TEST
}
  
export function getCurAddress () {
    return getAddress()
}

export const net_name = () => {
    return NET_TYPE == 'main'?'mainnet':'testnet'
}

export const net_name_capital = () => {
    return NET_TYPE == 'main'?'Mainnet':'Testnet'
}

export const InviteCondition = 10