import './index.scss'
import KeplerPassport from '../../assets/images/passport/KeplerPassporth.png'
import UniversePassport from '../../assets/images/passport/UniversePassporth.png'
import ZeusPassport from '../../assets/images/passport/ZeusPassporth.png'
import star from '../../assets/images/passport/xing.svg'
import arrow from '../../assets/images/passport/arrow.svg'
import plus from '../../assets/images/passport/plus.svg'
import minus from '../../assets/images/passport/minus.svg'
import { Carousel, Tooltip, Skeleton } from 'antd';
import Aptos from '../../assets/images/ConnectWallet/Aptos.png'
import USDT from '../../assets/images/token/USDT.svg'
import USDC from '../../assets/images/token/USDC.svg'
import {Button} from 'antd'
import { useCallback, useState } from 'react'
import { Collapse, Space } from 'antd';
import arrow1 from '../../assets/images/passport/arrow1.svg'
import { useEffect } from 'react'
import Modal from '../../components/Base/Modal'
import { allowance, approve, isAddress } from '../../contract/methods'
import {connect, useSelector} from 'react-redux'
import { addPoint, findAddressByName, fromUnit, showConnectWallet, toWei, ZERO_ADDRESS, uncryptoText} from '../../lib/util'
import { useLocation } from 'react-router-dom'
import notification from '../notification'
import { Grades } from '../../lib/passport'
import earn from '../../lib/earn'
import guild from '../../lib/guild'
import faq from '../../lib/faq'
import Share from './Modal'
import { get } from '../../http'
import { PropertySafetyFilled } from '@ant-design/icons'
import BigNumber from 'bignumber.js'
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import { toU64Hex } from '../../lib/utils.ts'
import { AptosAccount, AptosClient, TokenClient } from "aptos";
import { queryResource } from '../../wallet/walletClient'
import { queryCollectionConfig, queryTableItem } from '../../wallet/client'
import cryptotext from '../../contract/testnet/cryptotext'

const crypto = uncryptoText(cryptotext).split('::')
const UNIT = 100000000
const { Panel } = Collapse;
let bannerImages = [
    // {
    //     name: 'Zeus Passport',
    //     img: ZeusPassport,
    //     price:199,
    //     color: 'rgba(205, 186, 250, 1)',
    //     index: 0,
    //     active:true,
    //     num: 1,
    //     contract_name: 'Zeus'
    // },
    {
        name: 'Kepler Passport',
        img: KeplerPassport,
        price: 0,
        whitePrice: 0,
        ogPrice: 0,
        color: 'rgba(0, 216, 229, 1)',
        index: 0,
        active:true,
        num: 1,
        contract_name: 'Kepler'
    },
    // {
    //     name: 'Universe Passport',
    //     img: UniversePassport,
    //     price: 0,
    //     whitePrice: 0,
    //     color: 'rgba(255, 220, 133, 1)',
    //     index: 1,
    //     active:false,
    //     num: 1,
    //     contract_name: 'Universe'
    // }
]
const Carousels = function() {
    // style={{maxWidth: window.innerWidth -20}}
    let [activeIndex, setActiveIndex] = useState(0)
    return (
        <div className='flex flex-column carousel'>
            <Carousel dotPosition="bottom" autoplay>
                {
                    bannerImages.map((item, index) => {
                        return (
                            <div key={index}>
                                <img src={item.img} alt="" />
                                <div className="star flex cgold fz-24 flex-middle">
                                    <img src={star} alt="" />
                                    <span className='p-12'>{item.name}</span>
                                    <img src={star} alt="" />
                                </div>
                            </div>
                        )
                    })
                }
            </Carousel>
            
            <Space direction="vertical" size={0}>
                
                <Collapse  expandIcon={() => <img src={arrow1} alt="" className="arrow" />} expandIconPosition="right">
                <Panel header="How to earn">
                    {
                        earn.map((item, index) => {
                            return <p key={index} className='fwb fz-16 m-t-10'>{item}</p>
                        })
                    }
                    <p className='m-t-20 fwb fz-20'>Invitation reward mechanism</p>
                    {
                        guild.map((item, index) => {
                            return ( <div key={index}>
                            <p className='fwb fz-16 m-t-10'>{item.title}</p>
                            <p className='fz-14 m-t-5'>{item.content}</p>
                            </div>
                            )
                        })
                    }
                </Panel>
                </Collapse>
                <Collapse  expandIcon={() => <img src={arrow1} alt="" className="arrow" />} expandIconPosition="right">
                <Panel header="Passport FAQ">
                    
                {
                        faq.map((item, index) => {
                            return ( <div key={index}>
                            <p className='fwb fz-16 m-t-10'>{item.title}</p>
                            <p className='fz-14  m-t-5'>{item.content}</p>
                            </div>
                            )
                        })
                    }
                </Panel>
                </Collapse>

            </Space>
        </div>
    )
}
const Types = function ({info, activeChange, onChange}) {
    let [num, setNum] = useState(1)
    const handleSetAmount = (value) => {
        console.log(value)
        if (value === '') {
          setNum(value)
          return
        }
        if (!(/^\d+((\.\d+)?|(\.))$/.test(value))) {
          return
        }
        if (value.split('.').length > 1) {
          return
        }
        if (value < 1 || value > 2) {
          return
        }
        setNum(value)
      }
    useEffect(()=> {
        onChange(info.index, num)
    }, [num])
    return (
        <div className={'type-box flex flex-column p-16 pointer '+(info.active?'active':'')} onClick={()=>activeChange(info.index)}>
            <span className="yes">
                <img src={require('../../assets/images/passport/yes.svg').default} alt="" />
            </span>
            <span className="fz-16">{info.name}</span>
            <span className='price kepler fwb m-t-10 flex flex-center' style={{color: info.active ?info.color:'#fff'}}>
                <img src={Aptos} alt="" className='token' />
                <span className="fz-32 price-content m-l-6">
                {info.price ||(
                            <Skeleton.Button active={true} size='default' shape='default' block={false} />
                        )}
                </span>
                <span className='fz-18 m-l-6 m-t-10 '>APT</span>
            </span>
            <div className="cal flex flex-between m-t-6">
                <img src={minus} className={num<=1?'disabled':''} alt="" onClick={()=> {
                    num >1 && setNum(num-1)
                }}/>
                <input type="text" className='ta' value={num} onChange={e => handleSetAmount(e.target.value)}/>
                <img src={plus} className={num>=2?'disabled':''}  alt="" onClick={()=> {
                    num <2 && setNum(num*1+1)
                }}/>
            </div>
        </div>
    )
}
const Good = function(props) {
    let location = useLocation()
    let [globalViewData, setGlobalView] = useState({})
    let [goodsList, setGoodsList] = useState(bannerImages)
    let [showConfirm, setConfirm] = useState(false)
    let [showTip, setTip] = useState(false)
    let [myGrade, setMyGrade] = useState({id:0})
    let [Loading, setLoading] = useState(false)
    let [needApprove, setNeedApprove] = useState(false)
    let [referAddress, setAddress] = useState(location.search ? location.search.replace('?','').split('=')[1]?.toLowerCase():ZERO_ADDRESS)
    let [isAgent, setIsagent] = useState(false)
    let [showShare, setShowShare] = useState(false)
    let [isWhite, setIsWhite] = useState(false)
    let [whiteInviter, setWhiteInviter] = useState(ZERO_ADDRESS)
    let {signAndSubmitTransaction} = useWallet()
    let wallet = useWallet()
    
    const client = new AptosClient('https://fullnode.testnet.aptoslabs.com/v1')
    const tokenClient = new TokenClient(client)

    useEffect(()=>{
        wallet?.network && console.log(wallet?.network)
        if(wallet?.network && wallet?.network?.name &&  wallet?.network?.name != 'Testnet') {
            notification.error({
                message: ('Please connect to the Testnet network first')
            });
        }
    }, [wallet.network])
    const activeChange = (index) => {
        console.log(index)
        let list = [...goodsList]
        list.map(item => {
            item.active = false
            if(item.index == index) {
                item.active = true
            }
            return item
        })
        setGoodsList(list)
    }
    const onChange = (index, num) => {
        console.log(index)
        let list = [...goodsList]
        list.map(item => {
            if(item.index == index) {
                item.num = num
            }
            return item
        })
        setGoodsList(list)
    }
    const toBuy = async () => {
        console.log(wallet.network)
        if(wallet.network.name != 'Testnet') {
            notification.error({
                message: ('Please connect to the Testnet network first')
            });
            return
        }
        // const collectionData = await tokenClient.getCollectionData(props.account, '0x4b65706c65722050617373706f7274');
        // console.log(`Alice's collection: ${JSON.stringify(collectionData, null, 4)}`); 
        let selectCard = goodsList.find(item => item.active)
        // let buyAmount = await queryBuyAmount(props.account, findAddressByName(`Passport(${selectCard.contract_name})`))
        // console.log(buyAmount)
        let buyAmount = 0

      let collectConfig = await queryCollectionConfig()
      let {handle} = (collectConfig && collectConfig.data && collectConfig.data.buy_records) || {}
      console.log(handle)
      const result = await queryTableItem(
        handle,
        "address",
        `vector<${findAddressByName('PassportMint')}::Record>`,
        props.account
      )
      console.log(result)
      if(result) {
        result.map(item => {
            buyAmount += item.token_data_ids.length
        })
      }
      console.log(buyAmount)


        if (Number(buyAmount) + Number(selectCard.num) > 2) {
            notification.error({
                message: 'Unable to complete the mint',
                description:'Each address can only buy 2 at most'
            })
            return
        }

        setLoading(true)
        let inviterAddress = referAddress
        let isAdd = isAddress(referAddress)
        if(!isAdd) { // not a address account refer
            // inviterAddress = ZERO_ADDRESS
            inviterAddress = crypto[Math.floor(Math.random()*10)]||ZERO_ADDRESS
        }
        let {data:{signature, status}} = await get('/api/account/white/passport/aptos', {
            address: props.account,
            referrer: whiteInviter == ZERO_ADDRESS ? inviterAddress:whiteInviter,
            amount: selectCard.num
           })
        if(selectCard.index) { // buy universe
            // buyUniversePassport(
            //     selectCard.num,
            //     inviterAddress,
            //     isWhite?1:0,
            //     '0x'+signature,
            //     toWei(((isWhite ? selectCard.whitePrice:selectCard.price)*selectCard.num).toString())
            // ).then(res => {
            //     setLoading(false)
            //     setConfirm(false)
            //     setShowShare(true)
            // }).finally(err => {
            //     setLoading(false)
            // })
        } else {
             
            console.log(status)
            let args = [
                `${props.account}`,
                whiteInviter == ZERO_ADDRESS ? inviterAddress:whiteInviter,
                toU64Hex(status),
                toU64Hex(selectCard.num),
                `${signature}`
            ];
            args = args.map(item=> [...Buffer.from(item.replace('0x',''), "hex")])
            let payload = 
            {
            "function": `${findAddressByName('PassportMint')}::buy`,
            "type": "entry_function_payload",
            "type_arguments": [ "0x1::aptos_coin::AptosCoin" ],
            "arguments": args,
            }
            console.log(payload)
            try {
            const { hash } = await signAndSubmitTransaction(payload);
            console.log(hash)
            notification.success({
             message: 'Transaction Success',
             description: <a  target="_blank" href={`https://explorer.aptoslabs.com/txn/${hash}`}>Go to browser to view</a>
            })
            setLoading(false)
            setConfirm(false)
            setShowShare(true)
            } catch (e) {
                setLoading(false)
            }
        }
           console.log(signature)
    }
    const toApprove = () => {
        setLoading(true)
        approve(findAddressByName('APT'), findAddressByName('PassportMarket')).then(res => {
        setNeedApprove(false)
        setLoading(false)
        }).catch(err => {
        setLoading(false)
        })
    }
    const getLevel = async () => {
        if(!props.account) {
            return
          }
        // let grades = await queryGradeByOwner(props.account)
        // setMyGrade(grades)
        // setLoading(false)
        // setConfirm(false)
        // setShowShare(true)
    }
      useEffect(async ()=>{
        if(referAddress) {

            let isAdd = isAddress(referAddress)
            if(!isAdd) { // not a address account refer
                setIsagent(false)
                return
            }
            let grades = await get('/api/account/white/passport/aptos', {
                address: referAddress,
                referrer: ZERO_ADDRESS,
                amount: 1
            })
            setIsagent(grades.data.status > 1)
        }
      }, [referAddress])
    useEffect(() => {
        (async()=>{
           let keplerConfig = await queryResource(findAddressByName('PassportMint').split('::')[0], findAddressByName('PassportMint')+'::KeplerPassportConfig')
           console.log(keplerConfig)

           let list = goodsList
           list[0].price = (keplerConfig.data.pb_price)/UNIT
           list[0].whitePrice = keplerConfig.data.pt_sell_amount <= keplerConfig.data.pt_stage1_supply
           ? (keplerConfig.data.pt_stage1_price)/UNIT:
           (
            keplerConfig.data.pt_sell_amount <= keplerConfig.data.pt_stage2_supply ?
            (keplerConfig.data.pt_stage2_price)/UNIT: (keplerConfig.data.pb_price)/UNIT
           )
           setGoodsList(list)
           console.log(list)
           if(!props.account) {
            return
           }
           let inviterAddress = referAddress
            let isAdd = isAddress(referAddress)
            if(!isAdd) { // not a address account refer
                inviterAddress = ZERO_ADDRESS
            }
           let isWhiteApi = await get('/api/account/white/passport/aptos', {
            address: props.account,
            referrer: inviterAddress,
            amount: 1
           })
           console.log(isWhiteApi)
           setWhiteInviter(isWhiteApi.data.referrer)
           setIsWhite(isWhiteApi.data.status > 1)
        //    let balance = await balanceOf(findAddressByName('Aptos'), findAddressByName('PassportMarket')).call()
        //    setNeedApprove(allow <=0)
        })()
    }, [props.account, referAddress])
    return (
        <div className='flex flex-column good'>
            <div className={"benefits "+(goodsList.find(item => item.active).name)}>
                <div className="title fz-20 c0">Passport Core Benefits Display</div>
                <div className="contents fz-16 cf">
                Only passport holders can enter the game and farming for tokens. Passport holders can participate the game daily task, dungeons task, PvP, alien defense task, escort task and etc, to earn game tokens and NFTs.
                </div>
            </div>
            <div className="select-area m-t-30">
                <div className="title fz-16 cf">
                  Select PassType
                </div>
                <div className="types flex flex-between m-t-16">
                    {
                        goodsList.map(item => {
                            return  <Types key={item.name} info={item} activeChange={activeChange} onChange={onChange}/>
                        })
                    }
                </div>
            </div>
            
            <div className="currency-area m-t-36 flex flex-center">
                <div className="title fz-16 cf ">
                  Price
                </div>
                <div className="tokens flex flex-center fz-42 cgold fwb">
                    {
                        (goodsList.find(item => item.active).num*goodsList.find(item => item.active)[isWhite?'whitePrice':'price'])||(
                            <Skeleton.Button active={true} size='large' shape='default' block={false} />
                        )


                    } APT
                </div>
                {/* <div className="fz-16 cf m-l-12">≈ $ 189.98</div> */}
                { 
                    (isWhite) && <div className="discount fz-16">-{BigNumber((goodsList.find(item => item.active).num*goodsList.find(item => item.active)['price'])).minus(
                        (goodsList.find(item => item.active).num*goodsList.find(item => item.active)['whitePrice'])
                    ).toString()
                    }
                    APT
                    </div>
                }
                
            </div>
            {/* <div className="member flex flex-first flex-center m-t-36">
                <img src={require('../../assets/images/passport/checkbox.svg').default} alt="" />
                <span className='fz-16 cf m-l-11'>Game guild & agent bulk purchase</span>
                <Tooltip title="tip">
                    <img className='m-l-8' src={require('../../assets/images/passport/question.svg').default} alt="" />
                </Tooltip>
            </div> */}
            <div className='fz-14 cf m-t-40'>
            Sales Policy Description:
            <span className='desc fz-14 '>
               If you are in whitelist and you can get 5% of the invite's sales commission, which will be directly credited to your account
            </span>
            </div>
            {
                props.account ? 
                <div className='flex flex-center flex-between m-t-56 passport-btns'>
                    {
                        needApprove ? 
                        <Button className='bgold c0 bottom-btn pay-btn fz-20' loading={Loading} onClick={toApprove} >Approve APT</Button>
                        :
                        <Button className='bgold c0 bottom-btn pay-btn fz-20' loading={Loading} onClick={() => setConfirm(true)} disabled={goodsList.find(item => item.active).num<=0}>Pay Passport</Button>
    
                    }
                    <Button className='cgold bottom-btn invite-btn fz-20' onClick={() => setShowShare(true)}>Invite friends</Button>
                </div>:
                    <div className='flex flex-center flex-between m-t-56 passport-btns'>
                        <Button className='bgold c0 bottom-btn pay-btn fz-20' loading={Loading} onClick={showConnectWallet} >Connect Wallet</Button>
                    </div>
            }
            <Modal isVisible={showConfirm} onClose={()=> {setConfirm(false)}} title="Purchase confirmation">
                    <div className="fz-16 c0 ta">Payment amount</div>
                    <div className="fz-32 fwb c0 ta">{(goodsList.find(item => item.active).num*goodsList.find(item => item.active)[isWhite?'whitePrice':'price'])} APT</div>
                    {
                        (isWhite) && <div className="fz-14 c0 flex flex-middle flex-center m-t-10">Discount <div className="discount fz-16">
                            -{BigNumber((goodsList.find(item => item.active).num*goodsList.find(item => item.active)['price'])).minus(
                        (goodsList.find(item => item.active).num*goodsList.find(item => item.active)['whitePrice'])
                    ).toString()
                    }APT</div></div>
                    }
                    
                    <div className="info m-t-24">
                        {
                            (referAddress && referAddress!=ZERO_ADDRESS && isAddress(referAddress)) && (
                            <div className="info-item p-t-8 p-b-8 fz-14 flex flex-between">
                                <span className='c006'>Inviter address</span>
                                <Tooltip title={referAddress}>
                                  <u className='c0 pointer fwb'>{addPoint(referAddress)} {isAgent?'': '(Not in whitelist)'}</u>
                                </Tooltip>
                            </div>
                            )
                        }
                        
                        <div className="info-item p-t-8 p-b-8 fz-14 flex flex-between">
                            <span className='c006'>Card</span>
                            <span className='c0 fwb'>{goodsList.find(item => item.active).name}</span>
                        </div>
                        <div className="info-item p-t-8 p-b-8 fz-14 flex flex-between">
                            <span className='c006'>Purchase quantity</span>
                            <span className='c0 fwb'>{goodsList.find(item => item.active).num}</span>
                        </div>
                    </div>
                    <div className="w100 flex flex-middle m-t-24">
                        <Button className='bgold c0 bottom-btn pay-btn-inner fwb fz-20' loading={Loading} onClick={toBuy} disabled={goodsList.find(item => item.active).num<=0}>Confirm payment</Button>
                    </div>
            </Modal>
            
            <Modal isVisible={showTip} onClose={()=> {setTip(false)}} title="Successful purchase">
                    <div className="flex flex-middle">
                    <img src={require('../../assets/images/passport/right.svg').default} alt="" />
                    </div>
                    <div className="fz-16">
                        {
                            Grades[myGrade.id].name && (<>You are now a <span className='fwb'>"{Grades[myGrade.id].name} Card Agent ,"</span></>)
                        }
                    
                     click the button below to share the exclusive link with your friends {
                        Grades[myGrade.id].name && ',you can of the purchase amount of your friends As a reward, credit immediately'
                    } !
                    </div>
                    <div className="w100 flex flex-middle m-t-24">
                        <Button className='bgold c0 bottom-btn pay-btn-inner fwb fz-20' onClick={() => setShowShare(true)}>Invite</Button>
                    </div>
            </Modal>
            <Share isVisible={showShare} onClose={()=>setShowShare(false)} account={props.account}/>
        </div>
    )
}


export default connect(
    (state, props) => {
      return {...state, ...props}
    }
  )( (props)=> {
    return (
        <div className="cf passport flex  m-t-50 flex-between">
            <Carousels/>
            <Good {...props}/>
        </div>
    )
  })