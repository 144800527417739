
import './index.scss'
import Select from './Select'
import BUSD from '../../assets/images/token/BUSD.svg'
import USDT from '../../assets/images/token/USDT.svg'
import USDC from '../../assets/images/token/USDC.svg'
import APT from '../../assets/images/token/Aptos.png'
import Tangle from '../../assets/images/base/tangle.svg'

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useLocation } from 'react-router-dom'

import { useCallback, useState } from 'react'
import {Button, InputNumber, Skeleton, Tooltip } from 'antd'
import { useEffect } from 'react'
// import { balanceOf, buy, queryConfig, queryRoundPrices, querySaledUsdAmount, queryStableCoins } from '../../contract/methods/presale'
import { addPoint, ChainIdMap, findAddressByName, findNameByAddress, formatTime, formatTimeShort, fromUnit, getKeys, numFormat, showConnectWallet, toFixed, toUnit, toWei, ZERO_ADDRESS } from '../../lib/util'
import { connect } from 'react-redux'
import { getCurAddress } from '../../contract/testnet/address'
import { allowance, approve } from '../../contract/methods'
import { formatTimeStr } from 'antd/lib/statistic/utils'
import Modal from '../../components/Base/Modal'
import notification from '../notification'
import { isRegistered, queryModuleStorage } from '../../wallet/client'
import { useWallet } from '@manahippo/aptos-wallet-adapter';
import getBalance  from '../../wallet/walletClient'

import { getMinimumBalanceForRentExemptAccount } from '@solana/spl-token'
import { net_name, net_name_capital } from '../../global'
import { useRef } from 'react'
import store, { setPresalePrice, setReferMin, setToken } from '../../store'
import { post } from '../../http'
const UNIT = 100000000
const PAYMENT = 'APT'
let marks = {
    12: {
      label: '1Y'
    },
    24: {
      label: '2Y'
    },
    36: {
      label: '3Y'
    },
    48: {
      label: '4Y'
    },
    60: {
      label: '5Y'
    }
  }
let displayMoths = [
    {
        name: '1 year',
        value: 12
    },
    {
        name: '18 months',
        value: 18
    },
    {
        name: '2 years',
        value: 24
    },
    {
        name: '3 years',
        value: 36
    },
    {
        name: '4 years',
        value: 48
    },
    {
        name: '5 years',
        value: 60
    }
]

let iconMap = {
    'APT': APT,
    'USDC': USDC
}

let selectOptions = (currentList)=> {
    let arr = []
    currentList.map(item => {
        arr.push({
            label:<span className='options-item flex flex-center'><img className='token-icon m-r-5' src={item.icon} alt="" />{item.name}</span>,
            value: item.name
        })
    })
    return arr
}
const ChooseToken = (props) => {
    let [percent , setPercent] = useState(0)
    let [currentList, setCurrentList] = useState([])
    let [balance, setBalance] = useState(0)
    let [selectCur, setSelectCur] = useState(PAYMENT)
    let [inputNum, setInputNum] = useState('')
    let [showBigTip, setShowBigTip] = useState(false)
    let timer = useRef()
    const currencyChange = async (e) => {
        setSelectCur(e)
        props.curChange(e)
        if(props.account) {
            let bal = await getBalance(props.account, findAddressByName(PAYMENT))
            console.log(bal)
            setBalance((bal[0].value/UNIT))
        }
    }

    const polling = useCallback((flag = false) => {
        timer.current && clearInterval(timer.current)
        timer.current = setInterval(() => {
        currencyChange(PAYMENT)
        }, 20000)
      })
    useEffect(() => {
        polling()
        return () => clearInterval(timer.current)
    }, [])
    const handleSetAmount = (value) => {
        if (value === '') {
            setInputNum(value)
          return
        }
        if (!(/^\d+((\.\d+)?|(\.))$/.test(value))) {
          return
        }
        if (value.split('.').length > 1 && value.split('.')[1].length > 3) {
          return
        }
        if (value < 0) {
          return
        }
        setInputNum(value)
      }
    useEffect(async () => {
        // let currencies = ['APT']
        let currencies = [PAYMENT]
        let list = []
        currencies.map(item => {
            list.push({
                icon: iconMap[(item)],
                name: (item)
            })
        })
        setCurrentList(list)
        currencyChange(selectCur)
    }, [props.account])

    useEffect(()=>{
        let timer = null
        if(inputNum > props.max/UNIT) {
            setShowBigTip(true)
            setTimeout(()=>{
                setInputNum(props.max/UNIT)
            }, 2000)
        } else {
            clearTimeout(timer)
            timer = setTimeout(()=>{
                setShowBigTip(false)
            }, 6000)
        }
        props.onChange(inputNum)
    }, [inputNum])

    useEffect(() => {
        setInputNum('')
        setPercent(0)
        setSelectCur(PAYMENT)
        currencyChange(PAYMENT)
    }, [props.refresh])

    // useEffect(() => {
    //     setInterval(()=>{
    //         console.log('123123123123')
    //         // currencyChange(PAYMENT)
    //     }, 3000)
    // }, [])

    useEffect(()=>{
        if(props.account) {
            balance*percent > 0 ?setInputNum(toFixed(balance*percent/100, 3)||''):setInputNum('')
        } else {
            setInputNum('')
        }
    }, [percent, balance])
    return  (
        <div className="choose w100">
            <div className='choose-token flex flex-column p-l-24 p-r-16'>
                <div className="left-item flex flex-center">
                   <span className='c06 fz-14 m-b-9 flex-1 m-t-11'>Available:{props.account ? (balance ? toFixed(Number(balance), 3):0):'--'}</span>
                   <div className="percent flex flex-between m-t-11">
                        <div className={"pointer fz-14 percent-item ta c06 "+(percent == 25 ? 'active':'')} onClick={()=> {setPercent(25)}}>
                            25%
                        </div>
                        <div className={"pointer fz-14 percent-item ta c06 "+(percent == 50 ? 'active':'')}  onClick={()=> {setPercent(50)}}>
                            50%
                        </div>
                        <div className={"pointer fz-14 percent-item ta c06 "+(percent == 75 ? 'active':'')}  onClick={()=> {setPercent(75)}}>
                            75%
                        </div>
                        <div className={"pointer fz-14 percent-item ta c06 "+(percent == 100 ? 'active':'')}  onClick={()=> {setPercent(100)}}>
                            MAX
                        </div>
                    </div>
                   
                </div>
               
                <div className="right-item flex flex-center flex-between">
                    <input type="text" className='number-input fz-32 fwb c06' placeholder='0' value={inputNum} onChange={(e) => handleSetAmount(e.target.value)}/>
                    <div className="select-box flex flex-end">
                        <Select options={selectOptions(currentList)}  onChange={currencyChange}/>
                    </div>
                    
                </div>
            </div>
            {
                showBigTip && (
                    <div className='ce fz-14 p-l-20 m-t-10 '>
                        * Input amount can not be greater than Max buyable amount
                    </div>
                )
            }
            

        </div>
    )
}



export default connect(
    (state, props) => {
      return {...state, ...props}
    }
  )( (props)=> {
    let location = useLocation()
    let [selectMonths, setSelectMonths] = useState(60)
    let [showMonths, setShowMonths] = useState(false)
    let [config, setConfig] = useState({})
    let [rounds, setRounds] = useState(1)
    let [progress, setProgress] = useState(0)
    let [price, setPrice] = useState(0)
    let [inputNum, setInputNum] = useState('')
    let [cur, setCur] = useState(PAYMENT)
    let [needApprove, setNeedApprove] = useState(false)
    let [needReg, setNeedReg] = useState(false)
    let [loading, setLoading] = useState(false)
    let [isLoading, setIsLoading] = useState(false)
    let [refresh, setRefresh] = useState(0)
    let [isCheck, setIsCheck] = useState(false)
    let [showTip, setShowTip] = useState(false)
    let [claimStart, setClaimStart] = useState(new Date().getTime()/1000)
    let [isLogin, setIsLogin] = useState(false)
    let {signAndSubmitTransaction} = useWallet()
    let wallet = useWallet()
    let [referAddress, setAddress] = useState(location.search ? location.search.replace('?','').split('=')[1]?.toLowerCase():'')
    let [endDate, setEndDate] = useState(new Date(new Date(claimStart*1000).getFullYear(), new Date(claimStart*1000).getMonth()*1+60, new Date(claimStart*1000).getDate()))
    const numChange = (num) => {
        console.log(num)
        setInputNum(num)
    }

    useEffect(()=>{
        wallet?.network && console.log(wallet?.network)
        if(wallet?.network && wallet?.network?.name &&  !wallet?.network?.name.toLowerCase().includes(net_name())) {
            notification.error({
                message: (`Please connect to the ${net_name_capital()} network first`)
            });
        }
    }, [wallet?.network])
    const Login = async() => {
        console.log(wallet.account)
        let signature = await wallet.signMessage(
          {
          message: 'login',
          nonce:'login'
        }
        )
        let sign_send = ''
        let pub = ''
        if(Array.isArray(signature.signature)) {
           pub = getKeys(signature.bitmap, wallet.account.publicKey)
           sign_send = signature.signature[1]
        } else {
          sign_send = signature.signature
          pub = wallet.account.publicKey.substring(2)
        }
        console.log(signature)
        post('/api/account/connect', {
          chainId: ChainIdMap['Aptos'],
          user: props.account,
          signature: sign_send.startsWith('0x')? sign_send.substring(2):sign_send,
          pub: pub
        }).then(res => {
          props.dispatch(setToken(res.data.token))
          localStorage.setItem(props.account, res.data.token)
          setIsLogin(true)
        }).catch(err => {
          notification.error({
            message: ('Login Fail'),
            description: ('Something goes wrong')
        });
        })
      }
    const curChange = async (name) => {
        // setCur(name)
        // if(props.account) {
        //     console.log(cur)
        //     let allow = await allowance(findAddressByName(name), getCurAddress().Presale).call()
        //     console.log(allow)
        //     setNeedApprove(allow <= 0 )
        //   }
    }
    const toBuy = async () => {
    //    buy(findAddressByName(cur),toWei(Number(inputNum).toString()), selectMonths, (referAddress && referAddress.toLowerCase() != props.account)?referAddress:ZERO_ADDRESS).then(res => {
    //     setLoading(false)
    //     setRefresh(refresh+1)
    //     setShowTip(true)
    //     props.onSuccess()
    //    }).catch(err=>{
    //     setLoading(false)
    //    })

        
        if(!wallet.network.name.toLowerCase().includes(net_name())) {
            notification.error({
                message: (`Please connect to the ${net_name_capital()} network first`)
            });
            return
        }
        setLoading(true)
        let args = [
            inputNum*UNIT,
            selectMonths,
            (referAddress && referAddress.toLowerCase() != props.account)?referAddress:ZERO_ADDRESS
        ];
        // args = args.map(item=> [...Buffer.from(item.replace('0x',''), "hex")])
        let payload = 
        {
        "function": `${findAddressByName('Presale')}::buy_vekepl`,
        "type": "entry_function_payload",
        // "type_arguments": ["0x1::aptos_coin::AptosCoin", findAddressByName('VeKEPL')],
        "type_arguments": [findAddressByName(PAYMENT), findAddressByName('VeKEPL')],
        "arguments": args,
        }
        console.log(payload)
        try {
        const { hash } = await signAndSubmitTransaction(payload);
        console.log(hash)
        notification.success({
        message: 'Transaction Success',
        description: <a  target="_blank" href={`https://explorer.aptoslabs.com/txn/${hash}`}>Go to browser to view</a>
        })
        setLoading(false)
        setRefresh(refresh+1)
        setShowTip(true)
        props.onSuccess()
        } catch (e) {
            setLoading(false)
        }
    }
    
    let toRegister = async () => {
        if(!wallet.network.name.toLowerCase().includes(net_name())) {
            notification.error({
                message: (`Please connect to the ${net_name_capital()} network first`)
            });
            return
        }
        setLoading(true)
        // args = args.map(item=> [...Buffer.from(item.replace('0x',''), "hex")])
        let payload = 
        {
        "function": "0x1::managed_coin::register",
        "type": "entry_function_payload",
        "type_arguments": [findAddressByName('VeKEPL')],
        "arguments": [],
        }
        console.log(payload)
        try {
            const { hash } = await signAndSubmitTransaction(payload);
            console.log(hash)
            notification.success({
            message: 'Register Success'
            // description: <a  target="_blank" href={`https://explorer.aptoslabs.com/txn/${hash}`}>Go to browser to view</a>
            })
            setNeedReg(false)
            setLoading(false)
      }  catch (e) {
        setLoading(false)
      }
    }
    const copyAddress = useCallback(async () => {
        if(!props.account) {
          notification.error({
              message: ('Please connect your wallet first')
            });
            return
         }
        await navigator.clipboard.writeText('https://'+window.location.host+'?referee='+props.account);
        notification.success({
          message: ('The invitation link has been copied. Please paste it to your friends!'),
        });
      }, [props.account]);
    useEffect(() => {
        setEndDate(new Date(new Date(claimStart*1000).getFullYear(), new Date(claimStart*1000).getMonth()*1+selectMonths, new Date(claimStart*1000).getDate()))
    }, [selectMonths, claimStart])
    useEffect(async ()=>{
        if(!props.account) {
            return
        } else {
            let isRegister = await isRegistered(props.account)
            console.log('/////////////')
            console.log(isRegister)
            setNeedReg(isRegister?false:true)
        }
    }, [props.account])
    useEffect(async() => {
        setIsLoading(true)
        let storage = await queryModuleStorage(findAddressByName('Presale').split("::")[0])
         console.log(storage)
        // let saledUsd = await querySaledUsdAmount()
        // let config = await queryConfig()
        // let prices = await queryRoundPrices()
        let curentRounds = Math.floor((storage.data.saled_amount)/(storage.data.sale_amount_per_round))
        setConfig(storage.data)
        // console.log(config)
        setClaimStart(storage.data.claim_start_time)
        setRounds(curentRounds*1+1)
        setPrice(fromUnit(storage.data.round_prices[curentRounds]))
        store.dispatch(setPresalePrice(fromUnit(storage.data.round_prices[curentRounds])))
        setSelectMonths(60)
        setShowMonths(false)
        setProgress(((storage.data.saled_amount)%(storage.data.sale_amount_per_round))*100/(storage.data.sale_amount_per_round))
        setIsLoading(false)
        store.dispatch(setReferMin(storage.data.refeerer_min_buy_amount/UNIT))
    }, [refresh])

    useEffect(() => {
        setIsLogin(false)
        if(props.account && !localStorage.getItem(props.account)) {
            Login()
        } else {
            setIsLogin(true)
        }
    }, [props.account])
    return (
        <div className="private-box cf">
             <div className="fz-24 fwb p-l-24 p-r-24">KEPL Presale</div>
             <div className="flex flex-between w100 fz-16 m-t-30  p-l-24 p-r-24">
                <span>Presale Progress</span>
                <span className='c80'>Round <span className='cf'>{rounds}/10</span></span>
             </div>
             <div className=' p-l-24 p-r-24 w100'>
                <div className="progress flex flex-start w100 m-t-12">
                    <div className="progress-inner bgblue" style={{width: progress+'%'}}></div>
                    <Tooltip title={((config.saled_amount%config.sale_amount_per_round)/UNIT).toFixed(2)+'/'+config.sale_amount_per_round/UNIT}>
                        <div className="star"></div>
                        </Tooltip>
                    
                </div>
             </div>
             <div className="flex flex-between m-t-25  p-l-24 p-r-24">
                <span className='c06'>Current KEPL price</span>
                <span className='cf flex flex-center'>
                    1KEPL =
                    {/* <img src={require('../../assets/images/token/Aptos.png')} className="token-logo m-r-5" alt="" /> */}
                    {
                    isLoading ? <Skeleton.Button active={true} size='small' shape='default' block={false} />: <span> &nbsp;{toFixed(price, 6)}{PAYMENT}</span>
                    } 
                     
                    </span>
                    
             </div>
             
             <div className="flex flex-between m-t-12  p-l-24 p-r-24 min-max">
                 <span className='c06 flex min-max-inner'><span>Min buyable: </span>    
                {isLoading ? <Skeleton.Button active={true} size='small' shape='default' block={false} />: <span className='cf m-l-3'>{numFormat((config?.min_buy_amount/UNIT||0))} {PAYMENT}</span>}
                </span>
                <span className='c06 flex min-max-inner'><span>Max buyable: </span>  
                {isLoading ? <Skeleton.Button active={true} size='small' shape='default' block={false} />: <span className='cf m-l-3'>{numFormat((config?.max_buy_amount/UNIT||0))} {PAYMENT}</span>}
                </span> 
             </div>
             <div className="hr w100 m-t-24"></div>
             {/* choose-token */}
             <div className={"w100 p-24 " +(loading?'unable':'')}>
              <ChooseToken {...props} max={config?.max_buy_amount} onChange={numChange} refresh={refresh} curChange={curChange}/>
             </div>
             {/* choose-token */}
             <div className="p-l-24 p-t-10 flex flex-center pointer" onClick={()=>setIsCheck(!isCheck)}>  
                <div className={"checkbox  "+ (isCheck ?'active':'')} >
                    <img src={require('../../assets/images/private/yes.svg').default} alt="" />
                </div>
                <span className='fz-16 cf m-l-11 flex flex-center'>
                    <span style={{color: 'red', top: '3px', position: 'relative'}}>*&nbsp;</span>
                    Choose release period</span>
             </div>
             {
                isCheck && (
                   <>
                   <div className={"p-l-24 p-r-24 p-t-10 flex flex-center w100 "+(loading?'unable':'')}>  
                <div className="release-cycle w100">
                    <div className="cycle-inner p-t-12 p-l-16 p-b-12 p-r-16">
                        <div className="flex flex-between cycle">
                            <span className='fz-14 ce flex flex-center'>
                            Release in batches
                                <Tooltip title="The longer KEPL tokens are locked for, the more dividends received.">
                                  <img className='m-l-3' src={require('../../assets/images/passport/question.svg').default} alt="" />
                                </Tooltip>
                               
                            </span>
                            <span className='fz-14 c06'>until {formatTimeShort(endDate.getTime())}</span>
                        </div>
                        <div className="flex flex-between m-t-9 flex-center">
                            <span className='month-input fz-20'>
                              <InputNumber controls={
                                {
                                    downIcon: <span> <img src={Tangle} alt="" className='input-icon' /></span>,
                                    upIcon: <span> <img src={Tangle} alt="" className='rotate input-icon'/></span>
                                }
                              } className='month-input fz-20' min={12} max={60} defaultValue={60} value={selectMonths} onChange={setSelectMonths}/> Months
                            </span>
                            <div className="choose-btn fz-16 ta flex-middle flex flex-center pointer" onClick={()=>setShowMonths(!showMonths)}>
                                {showMonths ?'Hide':'Choose'} preset
                                <img src={Tangle} className="m-l-12" alt="" />
                            </div>
                        </div>
                    </div>
                    {
                        showMonths && <div className="months flex flex-wrap flex-between p-l-10 p-r-10 p-b-10">
                        {
                            displayMoths.reverse().map(item => {
                                return <div className={"month-item pointer " +(item.value == selectMonths ? 'active':'')} onClick={() => {setSelectMonths(item.value)}}>
                               {item.name}
                            </div>
                            })
                        }
                        
                    </div>
                    }
                    
             </div>
             </div>
             <div className={"p-l-46 p-r-46 p-t-24 p-b-24 "+(loading?'unable':'')}>
                <Slider className="rcslider"  marks={marks} min={12} max={60} onChange={setSelectMonths} value={selectMonths} defaultValue={selectMonths}/>
             </div>
                   
                   </>
                )
             }
             
             <div className="p-l-24 p-r-24 p-t-24 p-b-10">
             
             {/* <Button className='w100 submit-btn cf fz-20'>
                 2022.12.06 12:00 Start, Coming soon
                    </Button> */}
                     {
                    needReg ? <Button className='w100 submit-btn cf fz-20' loading={loading} onClick={toRegister}>
                    Register VeKEPL
                  </Button>:(
                    props.account ? (
                        isLogin ?
                        <Button className='w100 submit-btn cf fz-20' loading={loading} onClick={toBuy} disabled={inputNum < config?.min_buy_amount/UNIT || inputNum > config?.max_buy_amount/UNIT}>
                        {
                        inputNum < config?.min_buy_amount/UNIT ? (
                            inputNum == 0 ? 'Please input your amount':'Amount is too small'
                        ):(
                            inputNum > config?.max_buy_amount/UNIT ? (
                            'Amount is too large'
                            ):'Submit KEPL PreSale'
                        )
                        }
                    </Button>:
                        <Button className='w100 submit-btn cf fz-20' loading={loading} onClick={Login}>
                            Approve your wallet
                        </Button>
                    )
                    :
                    <Button className='w100 submit-btn cf fz-20' loading={loading} onClick={showConnectWallet}>
                        Connect Wallet
                    </Button>
                  )
                  
                }
                
             </div>
              {
                (referAddress && referAddress.toLowerCase() != props.account.toLowerCase()) && <div className='ta cd fz-14'>
                Inviter: <Tooltip title={referAddress}><u>{addPoint(referAddress)}</u></Tooltip>
             </div>
             }
             {
                inputNum>0 && inputNum <= config.max_buy_amount/UNIT && inputNum >= config.min_buy_amount/UNIT && <div className='ta cd fz-14'>
                 Expected: <u>{toFixed(inputNum/price, 6)||0} KEPL</u> <span className='fz-12'>(for reference only)</span>
                 </div>
             } 
             
             
             <Modal isVisible={showTip} onClose={() => {setShowTip(false)}}  title="Successful Presale">
                    <div className="flex flex-center flex-column fz-20 fwb">
                       <img src={require('../../assets/images/private/right.svg').default} alt="" width={100}/>
                        <span className='m-t-10'>Congratulations!</span> 
                    </div>
                    <div className="fz-16 m-t-10">
                    Congratulations on participating in the pre-sale! <br/>
                    veKEPL with same quantity as KEPL has been immediately transferred to your account;<br/>
                    When claiming begins,<br/>
                    You need to burn veKEPL in exchange for KEPL every month;<br/>
                    Invite your friends to join us!
                    </div>
                    <div className="w100 flex flex-middle m-t-24">
                        <Button className='bgblue cf bottom-btn pay-btn-inner fwb fz-20' onClick={copyAddress}>Invite</Button>
                    </div>
            </Modal>
        </div>
    )
})