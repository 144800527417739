export default [
    {
        title: "1, 5% invitation rebate, the premise must be that the inviter is a whitelist user, if A is a whitelist user, A invited B, B completed the mint, A will get a reward of 5% of the mint amount.",
        content: ""
    },
    {
        title:"2, The invitation reward is Apt, which is issued directly through the contract.",
        content: ""
    },
    {
        title:"3, Rank Rewards",
        content: ""
    },
    {
        title:"- TOP1, TOP2, TOP3, TOP4, TOP5 are awarded 5000USD, 3000USD, 2000USD, 1000USD, 800USD respectively;",
        content: ""
    },
    {
        title:"- 10 randomly drawn in the Rank list, each rewarded 1000USD;",
        content: ""
    },
    {
        title:"- PreSale quota and the opportunity for in-depth cooperation in subsequent activities.",
        content: ""
    }
]