export function toU64Hex(n:number):string {
    const buf = Buffer.allocUnsafe(8);
    buf.writeBigInt64BE(BigInt(n));
    return buf.toString("hex");
  }

  export function stringToHex(text: string) {
    const encoder = new TextEncoder();
    const encoded = encoder.encode(text);
    return Array.from(encoded, (i) => i.toString(16).padStart(2, "0")).join("");
  }
  export function camelCaseKeysToUnderscore(obj: any) {
    if (typeof obj !== 'object') {
      return obj;
    }
  
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const oldName in obj) {
      // Camel to underscore
      const newName = oldName.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);
  
      // Only process if names are different
      if (newName !== oldName) {
        // Check for the old property name to avoid a ReferenceError in strict mode.
        if (obj.hasOwnProperty(oldName)) {
          // eslint-disable-next-line no-param-reassign
          obj[newName] = obj[oldName];
          // eslint-disable-next-line no-param-reassign
          delete obj[oldName];
        }
      }
  
      // Recursion
      if (typeof obj[newName] === 'object') {
        // eslint-disable-next-line no-param-reassign
        obj[newName] = camelCaseKeysToUnderscore(obj[newName]);
      }
    }
    return obj;
  }