// custom modal

import React, { useState, useEffect, useRef } from 'react';
import './Modal.scss'
import { Modal, Button} from 'antd';
// import { CloseOutlined } from '@ant-design/icons';
import close from '../../assets/images/base/close.svg'
import classNames from 'classnames';
import { useCallback } from 'react';
import notification from '../notification';
import QRCode from 'qrcode.react'
import html2canvas from 'html2canvas'

export default function MyModal(props) {
  const copyAddress = useCallback(async () => {
    if(!props.account) {
      notification.error({
          message: ('Please connect your wallet first')
        });
        return
     }
    await navigator.clipboard.writeText('https://'+window.location.host+'?referee='+props.account);
    notification.success({
      message: ('Invitation link has been generated and copied, you can paste and send it to your friends now! '),
    });
  }, [props.account]);
  const downLoad = () => {
    html2canvas(document.getElementById('show-content'), {
      backgroundColor: null
    }).then((canvas) => {
      let imgUrl = canvas.toDataURL('image/png')
      var dlink = document.createElement('a')
      dlink.download = 'kepler passport share image'
      dlink.href = imgUrl
      dlink.dataset.downloadurl = ['image/png', dlink.download, dlink.href].join(':')
      document.body.appendChild(dlink)
      dlink.click()
      document.body.removeChild(dlink)
    })
  }
  return (
    <Modal visible={props.isVisible} width={props.width||388} closable={false} footer={false} className={classNames(["my-share", props.className])}>
      <div className="modal-header">
        <div className="modal-title">
          {
            props.hideclose ? '':<div className="modal-close" onClick={props.onClose}><img src={close} /></div>
          }
          
        </div>
      </div>
      <div className="modal-content p-l-30 p-r-30 p-b-30">
         <div className="share-content" id="show-content">
           <img src={require('./bg.png')} alt="" />
           <div className="main-content share-absolute">
               <div className='main-content-title ta fwb'>Kepler Passport</div>
               <div className='main-content-content ta m-t-5'>
                 If you are in whitelist, when a friend buys through the link you share, you can get a 5% commission back on the purchase amount
               </div>
           </div>
           <div className="share-desc share-absolute">
           Play, Earn and Trade in the blockchain
           </div>
           <div className="qrcode share-absolute flex flex-center flex-middle">
             <QRCode 
              id="qrcode"
              value={'https://'+window.location.host+'?referee='+props.account}
              size={66}
              fgColor="#000000"
             />
           </div>
         </div>


         <div className="share-box m-t-23 flex flex-between">
              <a className="share-item pointer flex flex-column flex-center" target="_blank" href={"https://twitter.com/intent/tweet?text=Passport is coming! You won’t miss it&url=https://"+window.location.host+"?referee="+props.account}>
                  <span className="icon-box flex flex-center flex-middle">
                    <img src={require('./twitter-dark.svg').default} alt="" />
                  </span>
                  <span className="share-name m-t-5 fz-12">
                     Twitter
                  </span>
              </a>
              <a className="share-item pointer flex flex-column flex-center" target="_blank" href={"https://telegram.me/share/url?text=Passport is coming! You won’t miss it&url=https://"+window.location.host+"?referee="+props.account}>
                  <span className="icon-box flex flex-center flex-middle">
                    <img src={require('./telegram-dark.svg').default} alt="" />
                  </span>
                  <span className="share-name m-t-5 fz-12">
                     Telegram
                  </span>
              </a>
              <div className="share-item pointer flex flex-column flex-center" onClick={copyAddress}>
                  <span className="icon-box flex flex-center flex-middle">
                    <img src={require('./link.svg').default} alt="" />
                  </span>
                  <span className="share-name m-t-5 fz-12">
                     Copy Link
                  </span>
              </div>
              <div className="share-item pointer flex flex-column flex-center" onClick={downLoad}>
                  <span className="icon-box flex flex-center flex-middle">
                    <img src={require('./download.svg').default} alt="" />
                  </span>
                  <span className="share-name m-t-5 fz-12">
                     Download
                  </span>
              </div>
          </div>

      </div>
      
      
    </Modal>
  )
}
