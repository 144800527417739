import { getNodeUrl } from "../global"; 


const getAccountResources = async function (address) {
  const url = `${getNodeUrl()}/accounts/${address}/resources`
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const resources = await fetch(url, options)
    .then(response => response.json())
    .catch(err => {
      return []
    })

    console.log(resources);

  return resources
}
export const queryResource = async function (address, resourceType) {
  const url = `${getNodeUrl()}/accounts/${address}/resource/${resourceType}`
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const resources = await fetch(url, options)
    .then(response => response.json())
    .catch(err => {
      return []
    })

    console.log(resources);

  return resources
}
export const  queryModules = async(moduleAddress)=> {
  let url = `${getNodeUrl()}/accounts/${moduleAddress}/modules`;
  console.log("queryModules: ", url);
  const resources = await fetch(url, { method: "GET" })
  .then(response => response.json())
  .catch(err => {
    return []
  })

  console.log(resources);

return resources
}

export default async function getBalance(address, coinType="0x1::aptos_coin::AptosCoin") {
  const resources = await getAccountResources(address)

  // console.log(resources);
  const ret = []
  const type = `0x1::coin::CoinStore<${coinType}>`
  resources.filter(el => {
      return el.type == type
    })
    .forEach(el => {
      // console.log(el);
      if (el.type == type) {
        ret.push({
          symbol: coinType.split("::")[1],
          value: el.data.coin.value,
        })
      } else {
        const symbol = el.type.split('::').pop().replace('>', '')
        ret.push({
          symbol: symbol,
          value: el.data.coin.value,
        })
      }
    })
  return ret
}